<template lang="pug">
q-layout(view="hHh Lpr fff" v-if="q.platform.is.desktop" )
    header-desktop(@showHeader="showHeader = $event" v-if="!franchise")
    header-franchise(v-else)
    q-page-container(:style="qpageStyle")
        router-view
        div(itemscope itemtype="https://schema.org/Organization")
            meta(itemprop="name" content="Общество с ограниченной ответственностью «АРТЭС»")
            meta(itemprop="logo" content="https://etabl.ru/logo.svg")
            meta(itemprop="telephone" content="8 (800) 200-5-400")
    footer-desktop(v-if="firstScroll" )
    q-no-ssr
        float-btn(v-if="!franchise && firstScroll")
    base-button-top
    plashka(v-if="!franchise && firstScroll")
template(v-if="q.platform.is.mobile")
    router-view
    footer-mobile(v-if="!franchise")
auth-desktop(v-if="q.platform.is.desktop && auth")
auth-mobile(
    v-if="q.platform.is.mobile && auth"
    buttonPrev)
base-full-spinner(
    v-if="basketFullLoading"
    fullVh
    :opacity="1"
)
    template(v-slot:spinner)
        q-spinner-dots(
            size="40px"
            color="positive"
        )
    template(v-slot:text)
        .text-positive.font-12.text-weight-medium.text-center переводим Вас на страницу оплаты
cookies-plash(v-if="!appCordova && !google")
</template>

<script>
import {Storage} from 'assets/helpers/storage';
import {Cookies, LocalStorage, Platform, useMeta, useQuasar} from 'quasar';
import {
    computed,
    defineAsyncComponent,
    getCurrentInstance,
    hydrateOnVisible,
    onMounted,
    ref,
} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';

const FloatBtn = defineAsyncComponent({
    loader: () => import('components/FloatBtn/FloatBtn.vue'),
});

const FooterMobile = defineAsyncComponent({
    loader: () => import('components/Footer/FooterMobile.vue'),
    hydrate: hydrateOnVisible(),
});

const CookiesPlash = defineAsyncComponent({
    loader: () => import('components/Cookies/Cookies.vue'),
    hydrate: hydrateOnVisible(),
});

const AuthMobile = defineAsyncComponent({
    loader: () => import('components/AuthMobile/Auth.vue'),
    hydrate: hydrateOnVisible(),
});

const AuthDesktop = defineAsyncComponent({
    loader: () => import('components/AuthDesktop/Auth.vue'),
    hydrate: hydrateOnVisible(),
});

const Plashka = defineAsyncComponent({
    loader: () => import('components/Plashka/Plashka.vue'),
    //hydrate: hydrateOnVisible(),
});

const BaseButtonTop = defineAsyncComponent({
    loader: () => import('components/Base/BaseButtonTop.vue'),
});

const HeaderDesktop = defineAsyncComponent({
    loader: () => import('components/Header/HeaderDesktop.vue'),
    hydrate: hydrateOnVisible(),
});

const FooterDesktop = defineAsyncComponent(() => import('components/Footer/FooterDesktop.vue'));

const BaseFullSpinner = defineAsyncComponent({
    loader: () => import('components/Base/BaseFullSpinner.vue'),
    hydrate: hydrateOnVisible(),
});

const HeaderFranchise = defineAsyncComponent({
    loader: () => import('components/Header/HeaderFranchise.vue'),
    hydrate: hydrateOnVisible(),
});

export default {
    components: {
        CookiesPlash,
        Plashka,
        FloatBtn,
        AuthMobile,
        AuthDesktop,
        FooterMobile,
        BaseButtonTop,
        HeaderDesktop,
        FooterDesktop,
        BaseFullSpinner,
        HeaderFranchise,
    },
    async preFetch({ store, ssrContext, redirect, currentRoute }) {
        const platform = process.env.SERVER
            ? Platform.parseSSR(ssrContext)
            : Platform;

        let google = platform?.userAgent.includes('Chrome-Lighthouse') || platform.userAgent.includes('Google Page Speed Insights');

        store.commit('device/SET_PLATFORM', platform);

        const cookies = process.env.SERVER
            ? Cookies?.parseSSR(ssrContext)
            : Cookies;

        await store.dispatch('user/CHECK_TOKEN', null, { root: true });

        if (cookies?.get('token')) {
            store.commit('user/SET_TOKEN', cookies.get('token'));

            try {
                await store.dispatch('user/REFRESH_TOKEN', cookies);
                await store.dispatch('user/GET_USER_DATA');
                await store.dispatch('basketNew/GET_PRODUCTS_IN_BASKET');
                await store.dispatch('basketNew/GET_PROMOCODES');
                await store.dispatch('user/GET_USER_FAVOURITES_COUNT');
                await store.dispatch('user/GET_USER_FAVOURITES_DRUGSTORES');
            } catch {
                cookies.remove('token');
            }
        }


        store.commit('user/SET_UID', cookies.get('_uid'));

        const userCityId = computed(() => store.getters['user/USER_CITY_ID']);

        const authhorized = store.getters['user/USER_AUTHORIZED'];

        let payload = null;

        // получаем город для динамических taitle и д discription
        let result = await store.dispatch('cityForm/GET_LOCATIONS');

        let city = null;
        if (currentRoute.params.city == '') {
            result.results.forEach((region) => {
                region.cities.find((item) => {
                    if (item.slug == 'belgorod') {
                        city = item;
                    }
                });
            });
        } else {
            result.results.forEach((region) => {
                region.cities.find((item) => {
                    if (item.slug == currentRoute.params.city) {
                        city = item;
                    }
                });
            });
        }
        store.commit('cityForm/SET_CITY', city);
    },
    setup() {
        const app = getCurrentInstance();
        const store = useStore();
        const q = useQuasar();
        const route = useRoute();
        const router = useRouter();

        const platform = computed(() => store.getters['device/PLATFORM']);

        const google = computed(() => platform.value.userAgent.includes('Chrome-Lighthouse') || platform.value.userAgent.includes('Google Page Speed Insights'));

        // Получаем  локальные данные пользователя
        store.commit('localStorage/SET_LOCAL_DATA', Storage.getLocalData());

        q.iconMapFn = (iconName) => {
            if (iconName.startsWith('etabl:') === true) {
                return {
                    cls: `etabl-icon etabl-icon-${iconName.substring(6)}`,
                };
            }
        };

        // отображение плавающей шапки
        const showHeader = ref(false);

        // авторизовн ли пользователь
        const userAuthorized = computed(() => store.getters['user/USER_AUTHORIZED']);

        if (route.query.freeDelivery == 'true') {
            store.commit('categories/SET_FREE_DELIVERY', true);
        }

        // точка авторизации
        const auth = computed(() => store.getters['auth/ENTRY_AUTH']);

        // обработка корзины
        const basketFullLoading = computed(() => store.getters['basket/BASKET_FULL_LOADING']);

        const qpageStyle = computed(() => {
            return {
                'padding-top': showHeader.value ? '82px' : '10px',
            };
        });

        const companyLogoUrl = 'https://etabl.ru/logo.svg';


        useMeta(() => {
            let currentRout = router.currentRoute.value.path;
            if (router.currentRoute.value.name === 'article') {
                currentRout = `/article/${router.currentRoute.value.params.slug}`;
            } else if (router.currentRoute.value.params.city !== '') {
                currentRout = `/${router.currentRoute.value.name}`;
            }
            return {
                link: [
                    { rel: 'canonical', href: `https://${process.env.APP_SERVICE_HOSTNAME}${currentRout}` },
                ],
                script: {
                    ldJson: {
                        type: 'application/ld+json',
                        innerHTML: `{
                            "@context": "https://schema.org/",
                            "@type": "Organization",
                            "name": "Общество с ограниченной ответственностью «АРТЭС»",
                            "logo": "${companyLogoUrl}"
                            "telephone": "8 (800) 200-5-400",
                        }`,
                    },
                },
            };
        });

        q.notify.setDefaults({
            position: q.platform.is.desktop ? '' : 'top',
        });

        const howToOrderPage = computed(() => {
            return router.currentRoute.value.name == 'how-to-order';
        });

        const franchise = computed(() => route.name == 'franchise' || route.name == 'franchise-rebrand' || route.name == 'franchise-new');

        const appCordova = computed(() => q.platform.is.cordova);

        const settingsCss = computed(() => store.getters['styles/stylesAccessibility']);
        const countSizeFont = computed(() => store.getters['styles/count']);
        const sizeFonts = computed(() => {
            return {
                font24: `${24 + countSizeFont.value}px`,
                font22: `${22 + countSizeFont.value}px`,
                font20: `${20 + countSizeFont.value}px`,
                font18: `${18 + countSizeFont.value}px`,
                font16: `${16 + countSizeFont.value}px`,
                font15: `${15 + countSizeFont.value}px`,
                font14: `${14 + countSizeFont.value}px`,
                font12: `${12 + countSizeFont.value}px`,
                font10: `${10 + countSizeFont.value}px`,
            };
        });
        const letterSpacingFonts = computed(() => `${settingsCss.value.letterSpacing}em`);
        const firstScroll = ref(false);
        const scrollOnce = () => {
            firstScroll.value = true;
        };
        onMounted(async () => {
            if (process.env.CLIENT) {
                window.addEventListener('scroll', scrollOnce);
            }
            if (route.query?.utm_source) {
                LocalStorage.set('utm_source', `${route.query.utm_source}`);
            }
            if (!userAuthorized.value) {
                if (q.localStorage.getItem('token')) {
                    store.commit('user/SET_TOKEN', q.localStorage.getItem('token'));

                    await store.dispatch('user/GET_USER_DATA');
                    await store.dispatch('user/USER_GET_COUNT_ACTIVE_ORDERS');
                }

                let redPath = null;
                let payload = null;

                store.commit('cityForm/SET_CITY', '');

                store.commit('cityForm/SET_CITY', '');
            }
            if (userAuthorized.value && store.getters['localStorage/BASKET_COUNT']) {
                for (let prod of store.getters['localStorage/BASKET_PRODUCTS']) {
                    try {
                        await store.dispatch('basketNew/PUT_PRODUCT_IN_BUSKET', {
                            product: {
                                id: prod.id,
                                amount: prod.amount || 1,
                                checked: true,
                            },
                        });
                    } catch (e) {
                        if (e.response.status == 422) store.commit('localStorage/DELETE_ITEM', prod.id);
                    }
                }
                store.commit('localStorage/CLEAR_CART');
                await store.dispatch('basketNew/GET_BASKET');
            }

            if (userAuthorized.value) {
                let cityId = LocalStorage.getItem('USER_CITY_ID');

                if (cityId == 'null' || cityId == null)
                    LocalStorage.set('USER_CITY_ID', '17c304fd-27c4-11e6-a3e4-001e6784c60c');

                if (store.getters['user/USER_CITY_ID'] != LocalStorage.getItem('USER_CITY_ID') && !!cityId) {
                    await store.dispatch('user/UPDATE_USER_CITY_ID', LocalStorage.getItem('USER_CITY_ID'));
                }

                if (process.env.CLIENT) {
                    window.addEventListener('pageshow', (event) => {
                        if (event.persisted) {
                            store.dispatch('user/USER_GET_COUNT_ACTIVE_ORDERS');
                        }
                    });
                }
            }
        });

        return {
            q,
            auth,
            franchise,
            showHeader,
            qpageStyle,
            appCordova,
            howToOrderPage,
            basketFullLoading,
            settingsCss,
            sizeFonts,
            platform,
            google,
            letterSpacingFonts,
            firstScroll,
            scrollOnce,
        };
    },
};
</script>

<style lang="scss">
html,
body,
#q-app {
    min-height: 100vh !important;
    background-color: #fbfafa !important;
}

div, p, span {
    letter-spacing: v-bind('letterSpacingFonts') !important;
}

body.q-ios-padding .q-dialog__inner {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

body.q-ios-padding .q-dialog__inner > div {
    max-height: 100vh !important;
}

.q-notification {
    margin: 48px 10px 0;
}

.q-img {
    display: v-bind('settingsCss.images') !important;
}

img {
    display: v-bind('settingsCss.images') !important;
}

// .font-24 {
//     font-size: v-bind('sizeFonts.font24') !important;
// }

// .font-22 {
//     font-size: v-bind('sizeFonts.font22') !important;
// }

// .font-20 {
//     font-size: v-bind('sizeFonts.font20') !important;
// }

// .font-18 {
//     font-size: v-bind('sizeFonts.font18') !important;
// }

// .font-16 {
//     font-size: v-bind('sizeFonts.font16') !important;
// }

// .font-15 {
//     font-size: v-bind('sizeFonts.font15') !important;
// }

// .font-14 {
//     font-size: v-bind('sizeFonts.font14') !important;
// }

// .font-12 {
//     font-size: v-bind('sizeFonts.font12') !important;
// }

// .font-10 {
//     font-size: v-bind('sizeFonts.font10') !important;
// }

</style>
